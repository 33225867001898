<template>
    <span @click.stop="changeVisible(true)">
        <slot></slot>
        <span @click.stop="">
            <el-dialog
                title="操作确认"
                :visible.sync="visible"
                width="600px"
            >
                <div class="fault-turndown-modal-content">
                    <span class="fault-turndown-tips">您确定要驳回吗？</span>
                    <el-input
                        v-model="jobRevertRemark"
                        type="textarea"
                        :rows="5"
                        placeholder="请输入内容"
                        class="agree-msg"
                    >
                    </el-input>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="visible = false;">取 消</el-button>
                    <el-button type="primary" @click="submitHandel">确 定</el-button>
                </span>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import './TurndownModal.less';
import axios from '@/utils/axios'
export default {
    name: 'TurndownModal',
    props: {
        jobCode: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            visible: false,
            jobRevertRemark: ''
        }
    },
    methods: {
        changeVisible(flag) {
            this.visible = flag;
        },
        submitHandel() {
            if (!this.jobRevertRemark) {
                this.$message.info('请输入驳回内容内容');
                return
            }
            axios.post('/api/job/revertJob', {
                jobRevertRemark: this.jobRevertRemark,
                jobCode: this.jobCode
            }).then(() => {
                this.$message.success('操作成功');
                this.visible = false;
            }).catch(err => {
                this.$message.error(err);
            });
        }
    }
}
</script>