<template>
    <span @click.stop="changeVisible(true)">
        <slot></slot>
        <span @click.stop="">
            <el-dialog
                title="操作确认"
                :visible.sync="visible"
                width="600px"
            >
                <div class="fault-agree-modal-content">
                    <span class="fault-agree-tips">您确定要同意办结吗？</span>
                    <el-input
                        v-model="jobFinishRemark"
                        type="textarea"
                        :rows="5"
                        placeholder="请输入内容"
                        class="agree-msg"
                    >
                    </el-input>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="visible = false;">取 消</el-button>
                    <el-button type="primary" @click="submitHandel">确 定</el-button>
                </span>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import './FaultAgreeModal.less';
import axios from '@/utils/axios'
export default {
    name: 'FaultAgreeModal',
    props: {
        jobCode: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            visible: false,
            jobFinishRemark: ''
        }
    },
    methods: {
        changeVisible(flag) {
            this.visible = flag;
        },
        submitHandel() {
            if (!this.jobFinishRemark) {
                this.$message.info('请输入完结内容');
                return
            }
            axios.post('/api/job/finishJob', {
                jobFinishRemark: this.jobFinishRemark,
                jobCode: this.jobCode
            }).then(() => {
                this.visible = false;
                this.$message.success('操作成功');
            }).catch(err => {   
                this.$message.error(err);
            });
        }
    }
}
</script>