<template>
    <span @click.stop="changeVisible(true)">
        <slot></slot>
        <span @click.stop="">
            <el-dialog
                title="电池检修情况预览"
                :visible.sync="visible"
                width="1220px"
                class="battery-repire-detail-modal"
            >
                <div class="battery-repire-detail-container">
                    <div class="exterior-container detail-container-box">
                        <div class="detail-title">
                            <div class="detail-title-icon">
                                <img :src="require('@/assets/img/battery-show.png')"/> 
                                <span>外观</span>
                            </div>
                            <div class="detail-title-tips">
                                <div class="tips-title">检修要求</div>
                                <div class="tips-content">
                                    对目标锂电池的外观的各项指标进行检查，包括把手，外壳，充电接口，铭牌等
                                </div>
                            </div>
                        </div>  
                        <div class="detail-content">
                            <div class="battery-repaire-info-item" v-for="(item, index) in exteriorData" :key="index">
                                <div class="repaire-status-icon">
                                    <img v-if="item.status == '1'" :src="require('@/assets/img/repaire-ok.png')"/>
                                    <img v-else :src="require('@/assets/img/repaire-error.png')"/>
                                </div>
                                <div class="repaire-info-content">
                                    {{item.lable}}
                                </div>  
                            </div>
                        </div>
                    </div>
                    <div class="temp-container detail-container-box">
                        <div class="detail-title">
                            <div class="detail-title-icon">
                                <img :src="require('@/assets/img/battery-temp.png')"/> 
                                <span>温度</span>
                            </div>
                            <div class="detail-title-tips">
                                <div class="tips-title">检修要求</div>
                                <div class="tips-content">
                                    在不同条件下检测电池的温度变化情况，包括正常情况，大功率充电电量过低时等
                                </div>
                            </div>
                        </div>  
                        <div class="detail-content">
                            <div class="battery-repaire-info-item" v-for="(item, index) in tempData" :key="index">
                                <div class="repaire-status-icon">
                                    <img v-if="item.status == '1'" :src="require('@/assets/img/repaire-ok.png')"/>
                                    <img v-else :src="require('@/assets/img/repaire-error.png')"/>
                                </div>
                                <div class="repaire-info-content">
                                    {{item.lable}}
                                </div>  
                            </div>
                        </div>
                    </div>
                    <div class="elec-container detail-container-box">
                        <div class="detail-title">
                            <div class="detail-title-icon">
                                <img :src="require('@/assets/img/battery-elect.png')"/> 
                                <span>充电</span>
                            </div>
                            <div class="detail-title-tips">
                                <div class="tips-title">巡视要求</div>
                                <div class="tips-content">
                                    核对电池充放电情况是否正常，包括是否可充电，是否能充满，耗时是否正常等
                                </div>
                            </div>
                        </div>  
                        <div class="detail-content">
                            <div class="battery-repaire-info-item" v-for="(item, index) in elecData" :key="index">
                                <div class="repaire-status-icon">
                                    <img v-if="item.status == '1'" :src="require('@/assets/img/repaire-ok.png')"/>
                                    <img v-else :src="require('@/assets/img/repaire-error.png')"/>
                                </div>
                                <div class="repaire-info-content">
                                    {{item.lable}}
                                </div>  
                            </div>
                        </div>
                    </div>
                    <div class="voltage-container detail-container-box">
                        <div class="detail-title">
                            <div class="detail-title-icon">
                                <img :src="require('@/assets/img/battery-elect.png')"/> 
                                <span>电压</span>
                            </div>
                            <div class="detail-title-tips">
                                <div class="tips-title">巡视要求</div>
                                <div class="tips-content">
                                   检查站点合作经营方的设施工作情况，避免因第三方设施和管理问题造成对国网服务品牌的不良影响
                                </div>
                            </div>
                        </div>  
                        <div class="detail-content">
                            <div class="battery-repaire-info-item" v-for="(item, index) in voltageData" :key="index">
                                <div class="repaire-status-icon">
                                    <img v-if="item.status == '1'" :src="require('@/assets/img/repaire-ok.png')"/>
                                    <img v-else :src="require('@/assets/img/repaire-error.png')"/>
                                </div>
                                <div class="repaire-info-content">
                                    {{item.lable}}
                                </div>  
                            </div>
                        </div>
                    </div>
                    <div class="repaire-list">
                        <!-- <div class="repaire-title">
                            检修记录
                        </div>
                        <div class="battery-repaire-info-item">
                            <div class="repaire-status-icon">
                                <img :src="require('@/assets/img/repaire-ok.png')"/>
                            </div>
                            <div class="repaire-info-content">
                                使用电池在正常充电情况下，核对温度变化幅度是否正常
                            </div>  
                        </div>
                        <div class="battery-repaire-record-tips">
                            <div class="lable">描述：</div>
                            <div class="value">提起电池环顾其四周，发现电池包铭牌和二维码清晰完好。</div>
                        </div> -->
                        <div class="repaire-photo">
                            <div class="repaire-photo-title">
                                照片：
                            </div>
                            <div class="repaire-photo-cards">
                                <div 
                                    class="photo-item" 
                                    v-for="(item, index) in photoArray" 
                                    :key="index"
                                >
                                    <div class="img-container">
                                        <img :src="item.sceneImg" style="width: 100%; height: 100%;"/>
                                    </div>
                                    <!-- <span class="img-name">正面</span> -->
                                </div>
                            </div>
                        </div>
                        <!-- <div class="repaire-time">2020-12-12 32:32:32</div> -->
                    </div>
                </div>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import axios from '@/utils/axios';
import './BatteryDetailModal.less';
export default {
    name: 'BatteryDetailModal',
    props: {
        batteryCode: {
            type: String,
            default: ''
        },
        jobCode: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            visible: false,
            exteriorData: [
                { lable: '核对电池把手是否完好', status: 1, },
                { lable: '核对电池包外壳是否完好无损', status: 1, },
                { lable: '核对电池包充电接口处的绝缘皮，插孔是否完好无偏移', status: 1, },
                { lable: '核对电池包铭牌/二维码是否完好', status: 1, },
                { lable: '其他', status: 1, },
            ],
            tempData: [
                { lable: '使用电池在正常充电情况下，核对温度变化幅度是否正常', status: 1, },
                { lable: '使用大功率电器充电，核对电池最高温度是否标准', status: 1, },
                { lable: '核对电池电量过低时，温度变化幅度是否正常', status: 1, },
            ],
            elecData: [
                { lable: '核对是否可以正常充电', status: 1, },
                { lable: '核对电量是否达到100%', status: 1, },
                { lable: '核对剩余50%电量情况下，充满耗时是否超过4小时', status: 1, },
                { lable: '核对电池闲置时电量耗损值是否正常', status: 1, },
                { lable: '其他', status: 1, },
            ],
            voltageData: [
                { lable: '正常充电，核对电压变化是否正常', status: 1, },
                { lable: '大功率充电，核对电压变化是否正常%', status: 1, },
                { lable: '核对闲置时电压变化变化是否正常', status: 1, },
                { lable: '模拟电池放电，核对放电时电压变化是否正常', status: 1, },
                { lable: '其他', status: 1, },
            ],
            photoArray: []
        }
    },
    methods: {
        changeVisible(flag) {
            this.visible = flag;
            if (flag) {
                this.getBatteryDetail(this.batteryCode, this.jobCode);
                this.getBatteryScene(this.batteryCode, this.jobCode);
            }
        },
        getBatteryDetail(batteryCode, jobCode) {
            axios.get(`/api/inspectionItem/pageList/${1}/${1000}`, {
                batteryCode,
                jobCode
            }).then(rs => {
                const { records } = rs;
                const exteriorData = records.slice(0,4).map(item => ({ lable: item.inspectionItemName, status: item.inspectionResult }));
                const tempData = records.slice(4,8).map(item => ({ lable: item.inspectionItemName, status: item.inspectionResult }));
                const elecData = records.slice(8,12).map(item => ({ lable: item.inspectionItemName, status: item.inspectionResult }));
                const voltageData = records.slice(12,16).map(item => ({ lable: item.inspectionItemName, status: item.inspectionResult }));
                this.exteriorData = exteriorData;
                this.tempData = tempData;
                this.elecData = elecData;
                this.voltageData = voltageData;
            }).catch(err => {
                this.$message.error(err);
            });
        }, 
        getBatteryScene(batteryCode, jobCode) {
            axios.get(`/api/inspectionScene/pageList/${1}/${1000}`, {
                batteryCode,
                jobCode
            }).then(rs => {
                this.photoArray  = rs.records;
            }).catch(err => {
                this.$message.error(err);
            });
        }
    }
}
</script>