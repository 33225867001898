<template>
    <div class="order-detail-page">
        <div class="order-detail-title">
            <i class="el-icon-arrow-left" @click="backToList"></i>
            <span>工单详情</span>
        </div>
        <div class="order-detail-container">
            <div class="order-left-container">
                <div class="order-detail">
                    <div class="title">
                        <div class="line"></div>
                        <span class="title-name">工单信息</span>
                    </div>
                    <div class="content">
                        <div class="order-detail-info">
                            <div 
                                class="order-detail-info-item"
                                v-for="(item, index) in orderInfo"
                                :key="index"
                            >
                                <div class="lable">{{ item.lable }}：</div>
                                <div class="value" :title="item.value">{{ item.value }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="history-fault">
                    <div class="title">
                        <div class="line"></div>
                        <span class="title-name">检修电池</span>
                    </div>
                    <div class="history-fault-content">
                        <div class="history-total-info">
                            <div class="total-info-item">
                                <span class="lable">电池数量：</span>
                                <span class="value">{{ batteryList.length }}</span>
                            </div>
                        </div>
                        <div class="history-list">
                            <el-table height="100%" :data="batteryList">
                                <el-table-column prop="batteryCode" label="电池ID" width="170"></el-table-column>
                                <el-table-column label="电池类别" prop="batteryType"></el-table-column>
                                <el-table-column label="电池类型" prop="batteryModel"></el-table-column>
                                <el-table-column label="联系人" prop="contacts"></el-table-column>
                                <el-table-column label="联系电话" prop="contactNumber"></el-table-column>
                                <el-table-column label="操作" prop="operate">
                                    <template slot-scope="{row}">
                                        <BatteryDetailModal :batteryCode="row.batteryCode" :jobCode="jobCode">
                                            <a class="operate-a">查看详情</a>
                                        </BatteryDetailModal>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="order-right-container">
                <div class="title">
                    <div class="line"></div>
                    <span class="title-name">工单状态</span>
                </div>
                <div class="order-status-container">
                    <div class="order-status-container-scroll">
                        <el-timeline>
                            <el-timeline-item 
                                v-for="(item, index) in orderStatusList"
                                :key="index"
                                class="order-active-status" 
                                color="#0096FF"
                            >
                                <span class="status-title">{{item.recordDesc}}</span>
                                <el-card class="status-detail" v-if="item.status === 40 && index === orderStatusList.length - 1">
                                    <div class="order-operate">
                                        <FaultAgreeModal :jobCode="jobCode">
                                            <div class="ok-button">同意办结</div>
                                        </FaultAgreeModal>
                                        <TurndownModal :jobCode="jobCode">
                                            <div class="no-button">驳回</div>
                                        </TurndownModal>
                                    </div>
                                </el-card>
                                <el-card class="status-detail" v-else>
                                    <p>{{item.recordCreateTime}}</p>
                                    <p>{{getOrderDesc(item.recordDesc)}}</p>
                                </el-card>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </div>
            </div>
        </div>
    </div>  
</template>

<script>
import BatteryDetailModal from './components/BatteryDetailModal';
import './OrderDetail.less';
import axios from '@/utils/axios';
import FaultAgreeModal from '../fault-rescue/components/FaultAgreeModal'
import TurndownModal from '../fault-rescue/components/TurndownModal'
const cycMap = {
    1: '天',
    2: '周',
    3: '月'
}
export default {
    name: 'OrderDetail',
    components: {
        BatteryDetailModal,
        FaultAgreeModal,
        TurndownModal
    },
    data() {
        return {
            jobCode: '',
            orderStatusList: [],
            orderInfo: [
                { lable: '检修编号', value: '3213213123123' },
                { lable: '检修状态', value: '待派单' },
                { lable: '创建时间', value: '2020-12-12 32:32:32' },
                { lable: '计划开始执行时间', value: '2020-12-12 32:32:32' },
                { lable: '周期（月）', value: 1 },
                { lable: '频率（次）', value: 1 },
                { lable: '当前周期开始时间', value: '2020-12-12 32:32:32' },
                { lable: '当前周期结束时间', value: '2020-12-12 32:32:32' },
                { lable: '检修描述', value: '211efefwfew' },
            ],
            batteryList: []
        }
    },
    methods: {
        backToList() {
            this.$router.push('/planned-overhaul')
        },
        getDetail(jobCode) {
            axios.get(`/api/inspectionCycleJob/getInspectionCycleJobDetail/${jobCode}`).then(rs => {
                const {
                    jobCode,
                    jobStatusLable,
                    createTime,
                    planBeginTime,
                    planEndTime,
                    inspectionRemark,
                    inspectionCycleInterval,
                    inspectionCycleUnit
                } = rs
                const orderInfo = [
                    { lable: '检修编号', value: jobCode },
                    { lable: '检修状态', value: jobStatusLable },
                    { lable: '创建时间', value: createTime },
                    { lable: '计划开始执行时间', value: planBeginTime },
                    { lable: `周期（${cycMap[inspectionCycleUnit]}）`, value: inspectionCycleInterval },
                    { lable: '频率（次）', value: '未知' },
                    { lable: '当前周期开始时间', value: planBeginTime },
                    { lable: '当前周期结束时间', value: planEndTime },
                    { lable: '检修描述', value: inspectionRemark },
                ];
                this.orderInfo = orderInfo;
            }).catch(err => {
                this.$message.error(err);
            });
        },
        getOrderStatus(jobCode) {
            axios.get(`/api/jobStatusRecord/getJobStatusRecordList/${jobCode}`).then(rs => {
                this.orderStatusList = rs;
            }).catch(err => {
                this.$message.error(err);
            });;
        },
        getJobBatteryList(jobCode) {
            axios.get(`/api/inspectionCycleJob/getInspectionCycleJobBattery/${jobCode}`).then(rs => {
                this.batteryList = rs;
            }).catch(err => {
                this.$message.error(err);
            });
        },
        getOrderDesc(str) {
            let result = '';
            if (str === '待接单') {
                result = '已派单';
            } else if (str === '处理中') {
                result = '已接单';
            } else if (str === '待审核') {
                result = '已处理'
            } else if (str === '已完成') {
                result = '已审核';
            }
            return result;
        },
    },
    mounted() {
        this.jobCode = this.$route.params.jobCode;
        this.getDetail(this.jobCode);
        this.getOrderStatus(this.jobCode);
        this.getJobBatteryList(this.jobCode);
    }
}
</script>